import axios from "axios";
import {
    URL_BASE
} from "../config";

export const SetPreregistro = async (data) => {
    let confirm = {result: false};
    try {
        await axios({
            url: `${URL_BASE}/api/preregistro`,
            data,
            method: 'post'
        })
            .then(response => {
                if (response.status === 200) {
                    let {data} = response.data
                    confirm.result = true;
                    confirm.existe = false;
                    confirm.preRegistroId = JSON.parse(data).preRegistroId;
                } else {
                    if (response.data.code === 220) {
                        confirm.result = true;
                        confirm.existe = true;
                    } else {
                        confirm.message = "Ocurrió un error al hacer el preregistro."
                    }
                }
            })
            .catch(e => {
                console.log(e, ":: error preregistro ::")
                confirm = {message: 'Error al intentando crear el pre-registro.'};
            })

    } catch (e) {
        confirm = {message: 'Error al intentar crear el pre-registro.'};
    }
    return confirm;
}


export const ReSendEmail = async (data) => {
    let obj = {result: false};
    console.log(data);
    try {
        await axios({
            url: `${URL_BASE}/api/preregistro/reenviar/email`,
            data,
            method: 'post'
        })
            .then(result => {
                console.log(result)
                if (result.status === 200 && result.data.status) obj.result = true;
            })
            .catch(e => {
                console.log("Error en Catch intentando reenviar email ::::", e)
            })
    } catch (e) {
        obj.message = "Error en intento de reeenviar email."
        console.log("Error en Catch ::::", e)
    }
    return obj;
}

export const GetDynamicData = async () => {
    let result = null;
    let parameters = {
        // email: "user@example.com",
        multiAccount: true, // True para que recorra el camino de multicuenta sin validar un correo
        confCountry: 1 // 1 es para que consulte la data de Colombia
    };
    try {
        await axios({
            url: `${URL_BASE}/api/preregistro/validar/email`,
            data: parameters,
            method: 'post'
        })
            .then(response => {
                if (response.status) {
                    result = response.data.data;
                }
            })
            .catch(e => {
                result = [];
            })
    } catch (e) {
        result = [];
    }
    return result;
}