import styled from "@emotion/styled";
import {colors} from "../UI";


export const Container =  styled.div`
    width:100%;
    max-width:420px;
    margin:auto;
    border-radius:10px;
    background:white;
    box-shadow:0 1px 3px rgba(0,0,0,.2);
    overflow:hidden auto;
    max-height:100%;
    position:relative;
    
    .header{
       h2{
           font-size:24px;
           font-family: var(--title-theme);
       } 
    }
    
    .tabs{
        border-bottom:1px solid ${colors.azul2};
        button{
            height:45px;
            width:100%;
            border:none;
            background:transparent;
            cursor:pointer;
            font-family:var( --title-theme);
            color:${colors.azul};
            
            &:hover{
                background:var(--grey-1);
            }
            
            &:disabled{
                cursor:not-allowed;
                opacity:.8;
            }
        }
        
        button.active{
            background-color:${colors.azul2};
            color:white;
        }
        
    }
`
