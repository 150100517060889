import styled from "@emotion/styled";
import {colors} from "../index";

export const Container = styled.div`

    flex:${props=> props.flex};
    position:relative;
    width:100%;
    
    .icon{
        position:absolute;
        left:0;
        top:0;
        width:auto;
        height:100%;
        font-size:22px;
        color:${props=> props.colorIcon};
        z-index:10;
        height:56px;
    }
    
    input,select {
        border:none;
        height:45px;
        width:100%;
        border:1px solid ${props=> props.error ? "red" : colors.azul};
        color:${props=> props.error ? "red" : colors.text};
        cursor:pointer;
        border-radius:4px;
        background:transparent;
        padding:${props => props.before ? '0 0.5em 0 45px' : '0 .5em'};
        
        &:disabled{
            background:var(--grey-1);
            border-color:var(--grey-1);
            cursor:not-allowed;
            color:#999999;
        }
        
        &:focus{
            border-color:${props=> props.error ? "red" : colors.blue};
            outline:none;
        }
    } 
    
    .before{
        position:absolute;
        left:0;
        line-height:40px;
        height:100%;
        font-weight:bold;
        color:#999999;
    }  

`;