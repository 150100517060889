import React from 'react';

const Title = (props) => {
    return (
        <div className={'text-center header p-3'}>
            <h2>¡Regístrese sin costo y reciba pagos sin complicaciones!</h2>
            <p>Empiece a cobrar con nuevos medios de pago en menos de 10 minutos.</p>
        </div>
    )
}

export default React.memo(Title);