import styled from "@emotion/styled";
import {colors, transition} from "../index";

export const Container =styled.div`
    width:18px;
    height:18px;
    border:1px solid ${props=> props.disabled ? "#999999" : colors.azul };
    border-radius:4px;
    padding:0;
    position:relative;
    
    &:hover{
        background-color:#f1f1f2;
    }
    
    input{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index:3;
        opacity:0;
        cursor:pointer;
        
        &:disabled{
           cursor:not-allowed;
        }
    }
    
    .chulo{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index:2;
        color:black;
        opacity:0;
        transform:scale(.5);
        transition:${transition};
        font-size:14px;
        line-height:40px;
        padding:0;
        text-align:center;
        color:white;
        color:${colors.azul2};
        
        i{
          line-height:14px;
          height:14px;
          color:white;
        }
    }
    
    .chulo.active{
        transform:scale(1);
        opacity:1;        
        border-radius:2px;
        background:${props => props.disabled ? "#999999" : colors.azul2};
    }
`;
