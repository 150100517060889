import React, {useState} from 'react';
import ShowModal from "../../../modal/ShowModal";
import Flex from "../../../UI/Flex/Flex";
import Btn from "../../../UI/btn/btn";
import registroExitoso from "./../../../img/registroExitoso.png"
import {ReSendEmail} from "../../../../servicios/serviceApirequest";

const ModalResumen = ({show, handler, type, preRegistroId}) => {

    let [resending, set] = useState(false);
    let [resended, setR] = useState(false);

    let resendEmail = async () => {
        set(true);
        let resend = await ReSendEmail({preRegistroId})
        if (resend.result) {
            set(false)
            setR(true);
            setTimeout(() => {
                setR(false)
            }, 5000)
        }
    }

    return (
        <ShowModal
            show={show} onClose={() => handler()}
            hideClose
        >
            <Flex className={"wc py-3"} direction={"column"}>
                {type ?
                    <>
                        <img src="https://multimedia-epayco.s3.amazonaws.com/dashboard/modal_icons/warning.png"
                             alt="" width={"70px"} height={"auto"} className={"mx-auto d-inline-block"}
                        />
                        <Flex className={"p-3 text-center"}>
                            <h3 className={"wc text-center ft"}>¡Lo sentimos!</h3>
                            <p>
                                Su número de documento y/o correo ya se encuentran registrados. Puede Iniciar Sesión con
                                el vínculo inferior o corregir los datos e intentarlo nuevamente.
                            </p>
                        </Flex>
                    </> :
                    <>
                        <Flex className={" text-center"}>
                            <h3 className={"wc text-center ft"}>Revise su correo</h3>
                            <p>
                                Hemos enviado un correo electrónico a la dirección suministrada.
                                <strong className={"ft d-inline-block"}>¡Siga los pasos de este correo electrónico para
                                    continuar con el registro!</strong>
                            </p>
                        </Flex>
                        <div className={"wc py-3 text-center"}>
                            <img src={registroExitoso} alt="" width={"100%"} style={{maxWidth: "210px"}}
                                 className={"mx-auto"}/>
                        </div>
                        <div className={"wc text-center pb-3"}>
                            {resending ?
                                <p className={"cto mb-4 text-center wc ft"}><strong>Reenviando...</strong></p> :

                                (resended ?
                                        <p className={"cto mb-4 text-center wc ft"}>
                                            <strong>¡Email reenviado con éxito!</strong>
                                        </p> :
                                        <p
                                            className={"cto mb-4 text-center wc ft"}
                                            onClick={() => resendEmail()}
                                        >
                                            <strong>Reenviar correo</strong>
                                        </p>
                                )

                            }
                            <small>Si no recibió el correo electrónico de confirmación, revise la carpeta de correo no
                                deseado.
                                Si continúan los inconvenientes, por favor escribirnos al siguiente correo
                                electrónico: <span className={"cto   "}>servicio.cliente@payco.co</span></small>
                        </div>

                    </>
                }
                <div className={"wc px-2"}>
                    <Btn onClick={() => handler()} borderR={6} disabled={resending}>
                        {type ? "Aceptar" : "Terminar"}
                    </Btn>
                </div>
            </Flex>
        </ShowModal>

    )
}

export default React.memo(ModalResumen);
