import React, {useState} from 'react';
import {Container} from "./styles";
import ShowError from "./showError";
import {colors} from "../index";
import NumberFormat from "react-number-format";

const Field = (props) => {

    const [focus, set] = useState(false);

    return (
        <Container
            before={!!(props.before)}
            className={props.className}
            error={!!props.error}
            flex={props.flex || '1 0 100%'}
            icon={!!props.icon}
            colorIcon={!!props.error ? 'red' : props.disabled ? colors.disabled : (focus ? colors.blue : colors.blueDark)}
        >
            {props.before &&
            <div className={"before"}>
                {props.before()}
            </div>
            }

            {props.children ?
                props.children :
                <>
                    {props.type === "number" &&
                    <NumberFormat
                        type={'tel'}
                        name={props.name}
                        ref={props.theRef}
                        value={props.value || ''}
                        disabled={props.disabled}
                        className={"to-right wc"}
                        format={props.format}
                        onValueChange={(values) => {
                            const {value} = values;
                            let obj = {target: {value: value,name:props.name}}
                            props.onChange(obj)
                        }}
                        placeholder={props.placeholder}
                    />
                    }


                    {(!props.type && !props.customInput ) &&
                    <input
                        ref={props.theRef}
                        type={props.type}
                        onChange={e => props.onChange(e)}
                        onFocus={() => set(true)}
                        onBlur={() => set(false)}
                        disabled={props.disabled}
                        placeholder={props.placeholder}
                        name={props.name}
                        value={props.value || ''}
                    />
                    }
                </>
            }
            {!props.noError && <ShowError error={props.error}/>}
        </Container>)

}

export default React.memo(Field);