import React from 'react';
import {Container} from "./styles";
import {colors} from "../index";
import Loading from "../loading/loading";
import Flex from "../Flex/Flex";


const Btn = ({
         type='',
         disabled=false,
         big=true,
         color= colors.azul2,
         flex= "0 0 auto",
         loading= false,
         onClick= ( ) => null,
         children,
         className,
         borderR,
         btnType="submit"
     }) => {

    let height = big ? 45 : 35;

    return (
        <Container
            type={type === "line" ? '1' : undefined }
            height={height}
            flex={flex}
            color={disabled ? type === "line" ? "white" : colors.disabled : color}
            disabled={disabled}
            className={className}
            loading={loading ? '1': undefined }
            borderR={borderR}
        >
            <button disabled={disabled} onClick={onClick} type={btnType}>{children}</button>
            {loading &&
            <Flex className="loading " direction={"column"} alg={"center"}>
                <Loading
                    size={big?  40 : 30 }
                    sizeBr={big ? 5 : 3 }
                    colorLine={type === "line" ? color : "white" }
                />
            </Flex>
            }
        </Container>
    )
}

export default React.memo(Btn);