import styled from "@emotion/styled";
import {colors} from "../index";

export const Container = styled.div`
     flex:${props=> props.flex};
     position:relative;
     
     button{
        width:100%;    
        border:1px solid ${props=> props.color};
        min-height:${props=> props.height}px;
        border-radius:${props=> props.borderR}px;
        background-color:${props=> props.type ? "white" :  props.color};
        color:${props=> props.loading ? (props.type ? "white" : props.color ) : (props.type ? props.color : "white")};
        padding-bottom:5px;
        
        
        &:hover{}
        
        &:disabled{
            background:${props => props.type ? 'white': 'var(--grey-2)'};
            color:${props => props.type ? colors.disabled : 'white'};
            border-color: ${props => props.loading ? props.color : colors.disabled };
            cursor:${ props=> props.loading ? 'waiting' :"not-allowed"};
        }
     }
     
    .loading{
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        z-index:10;
        cursor:wait;
    }
`;

