import React from 'react';
import powered from "../../img/powered-by.png";

const FormFooter = () => {
    return (
        <>
            <div className={'text-center wc pt-3'}>
                <p>¿Ya tiene una cuenta? <a
                    rel="noreferrer"
                    href="https://dashboard.epayco.co/login"
                    target={"_blank"}
                    className={"cto d-inline-block"}><strong>INICIE SESION AQUí</strong></a>
                </p>
            </div>
            <div className={"wc text-center"}>
                <img src={powered} alt=""/>
            </div>
        </>
    )
}

export default React.memo(FormFooter);