export const onlyLetters = (e,value)=>{
    let val =  e.target.value.replace(/[^a-zA-ZàèìòùáéíóúÄëïöü\s]/g,'')
    val = val.replace('  ',' ');
    let obj = {target:{value:val,name:e.target.name}}
    return value ? val : obj;
}

export const onlyLettersNumbers = (e,value)=>{
    let val =  e.target.value.replace(/[^0-9a-zA-ZàèìòùáéíóúÄëïöü\s]/g,'')
    let obj = {target:{value:val,name:e.target.name}}
    return value ? val : obj;
}

let formato = function (Dta, formt) {
    let cleared = Dta.replace(formt, '');
    return cleared;
};

export const validateTypeDoc = (active,value)=>{
    let rx = active === 'PPN' ? /[^a-zA-Z0-9]/g : /[^0-9]/g;
    let limit = active === 'PPN' ? 14 : 10;
    let vc = formato(value, rx).substring(0, limit);
    vc.trim();
    return vc;
}

export const BuildData = (data) => {
    let {
        userType,
        firstName,
        lastName,
        typeDoc,
        docN,
        phoneNumber,
        email,
        digit,
        commerceName,
        idAliado,
        model,
        documentExpirationDate
    } = data;

    return ({
        userType: userType,
        names: firstName || '',
        surNames: lastName || '',
        companyName: commerceName || '',
        docType: typeDoc,
        docNumber: docN,
        digito: digit || '',
        country: "CO",
        celNumber: phoneNumber,
        subCategory: "",
        category: "",
        email: email,
        idAliado: idAliado,
        gateway: model === "gateway",
        documentExpirationDate,
        metaTag: "Dashboard - Iframe"
    })
}