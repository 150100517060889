import React from 'react';
import {Collapse} from 'react-collapse';

const ShowError = ({error}) => {
    return (
        <Collapse isOpened={!!(error)}>
            <small className={"errortext"}>{error}</small>
        </Collapse>
    )
}

export default React.memo(ShowError);