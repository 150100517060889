import React, { useState, useEffect } from 'react';
import Flex from "../../UI/Flex/Flex";
import Check from "../../UI/Check/Check";
import ShowError from "../../UI/field/showError";

const TerminosCondidion = ({ errors, touched, values, setFieldValue, disabled, model, termConditions }) => {

    const [urlTreatment, setUrlTreatment] = useState();
    const [urlTerms, setUrlTerms] = useState();

    useEffect(() => {
        if (termConditions) {
            setUrlTerms(model === "gateway"? termConditions[0].urlGateway: termConditions[0].url);
            setUrlTreatment(model === "gateway"? termConditions[1].urlGateway: termConditions[1].url);
        }
    }, [termConditions, model])

    return (
        <div className={"wc mb-3"}>
            <Flex alg={"flex-start"} jc={"flex-start"} className={"mb-2"}>
                <Check
                    set={() => setFieldValue('terms1', !values.terms1)}
                    active={values.terms1}
                    name={"terms1"}
                    disabled={disabled}
                />
                <Flex flex={"1 0 80%"} className={"ps-2"}>
                    <small>Confirmo que acepto los <a
                        href={urlTerms}
                        className={"cto"}  rel="noreferrer" target={"_blank"}>Términos y condiciones</a> de los
                        servicios ofrecidos por ePayco.</small>
                </Flex>
                <div className={"wc"}>
                    <ShowError error={(errors.terms2 && touched.terms2) && errors.terms1}/>
                </div>
            </Flex>

            <Flex alg={"flex-start"} jc={"flex-start"} className={"mb-2"}>
                <Check
                    set={() => setFieldValue('terms2', !values.terms2)}
                    active={values.terms2}
                    name={"terms2"}
                    disabled={disabled}
                />
                <Flex flex={"1 0 80%"} className={"ps-2"}>
                    <small>
                        Autorizo de manera previa, expresa e inequívoca que mis datos personales sean tratados por epayco.com conforme a las finalidades establecidas en su <a
                        href={urlTreatment}
                        target={"_blank"}
                        rel="noreferrer"
                        className={"cto"}>Política de tratamiento de datos personales</a>
                    </small>
                </Flex>
                <div className={"wc"}>

                    <ShowError error={(errors.terms2 && touched.terms2) && errors.terms2}/>
                </div>
            </Flex>
        </div>
    )
}

export default React.memo(TerminosCondidion);