import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Fomulario from "./components/Fomulario/formulario";

function App() {
  return (
    <div className="main-container p-1 p-sm-2">
        <Router>
            <Switch>
                <Route exact path={"/"} render={()=> <Fomulario/> } />
            </Switch>
        </Router>
    </div>
  );
}

export default App;
