import React, {useState, useEffect} from 'react';
import {Formik} from 'formik';
import Btn from "../UI/btn/btn";
import {Container} from "./styles";
import Field from "../UI/field/field";
import {CommerceSchema, PersonSchema} from "./schemasValidations";
import NitComp from "./NITcomp";
import {Collapse} from "react-collapse/lib/Collapse";
import {
    onlyLetters,
    BuildData,
    validateTypeDoc
} from "./helpers";
import Tabs from "./componentes/tabs";
import Title from "./componentes/title";
import TerminosCondidion from "./componentes/terminosCondiciones";
import { SetPreregistro, GetDynamicData } from "../../servicios/serviceApirequest";
import ModalResumen from "./componentes/modal/modal";
import {
    personValInit,
    commerceValInit
} from "../../config";
import {withRouter} from "react-router-dom";
import RecaptchaComp from "./componentes/Recaptcha/Recaptcha";
import FormFooter from "./componentes/formFooter";

const today = new Date();
export let dynamicData = {};

const Fomulario = () => {

    let [idCliente, setIdCliente] = useState(4877);
    let [userType, setUserType] = useState("persona"); /* persona ó comercio */
    let [model, setModel] = useState("agregador"); /* agregador ó gateway */
    let [processing, setProcessing] = useState(false);
    let [modal, setModal] = useState(false);
    let [type, setType] = useState(0);
    let [preRegistroId, setPreRegistroId] = useState(null);
    const [typeDate, setTypeDate] = useState('text');
    const [expirationDateRequired, setExpirationDateRequired] = useState(false)


    let path = window.location.hash.split('/');
    let parameters = path[path.length - 1].replace('#', '');

    const handleGetDynamicData = async () => {
        setProcessing(true);
        let response = await GetDynamicData();
        dynamicData = response? response: {};
        setProcessing(false);
    }

    const handleChangeTypeDocument = (e, setFieldValue) => {
        setFieldValue('typeDoc', e.target.value);
        const auxIdenttyPerson = dynamicData.identityPerson?.find((item) => item.codigo === e.target.value);
        setExpirationDateRequired(auxIdenttyPerson.expirationDateRequired? auxIdenttyPerson.expirationDateRequired: false);
    }

    useEffect(() => {
        handleGetDynamicData();
        if (parameters) {
            let items = parameters.split('&');
            let id = parseInt(items[0]);
            Number.isInteger(id) && setIdCliente(id);

            let params = new URLSearchParams(window.location.hash);
            let paramType = params.get("tipo");
            switch (paramType) {
                case "agregador":
                    setModel(paramType);
                    break;
                case "gateway":
                    setModel(paramType);
                    setUserType("comercio");
                    break;
                default:
                    break;
            }
        } 
    }, [])

    return (
        <Container className={"p-2 p-sm-3 "}>

            <ModalResumen
                type={type}
                show={modal}
                handler={() => setModal(false)}
                preRegistroId={preRegistroId}
            />

            <Title/>
            <Tabs userType={userType} setUserType={setUserType} model={model} disabled={processing}/>

            <Formik
                enableReinitialize
                initialValues={userType === "persona" ? personValInit : commerceValInit}
                validationSchema={userType === "persona" ? PersonSchema : CommerceSchema}
                onSubmit={async (values, {resetForm}) => {
                    setProcessing(true);
                    values.userType = userType;
                    values.idAliado = idCliente;
                    values.model = model;
                    let response = await SetPreregistro(BuildData(values))
                    setProcessing(false);
                    if (response.result) {
                        setType(response.existe ? 1 : 0)
                        setModal(true);
                        setPreRegistroId(response.preRegistroId)
                        resetForm();
                    }
                }}
            >
                {({
                      errors,
                      values,
                      touched,
                      handleSubmit,
                      setFieldValue,
                      handleChange
                  }) => (
                    <div className={'wc'}>
                        <form onSubmit={e => {
                            e.preventDefault();
                            handleSubmit();
                        }}>
                            <div className={"row py-2 p-1 align-top"}>

                                <Collapse isOpened={userType === "comercio"}>
                                    <div className="row pb-2">
                                        <Field
                                            className={"py-2 px-2"}
                                            placeholder={"Nombre de la empresa"}
                                            error={(errors.commerceName && touched.commerceName) && errors.commerceName}
                                            name={"commerceName"}
                                            value={values.commerceName}
                                            onChange={(e) => setFieldValue('commerceName', e.target.value)}
                                            disabled={processing}
                                        />
                                        <NitComp
                                            values={values}
                                            error={errors}
                                            touched={touched}
                                            onChange={(name, val) => setFieldValue(name, val)}
                                            identity={dynamicData.identityEnterprise}
                                            processing={processing}
                                        />
                                    </div>
                                </Collapse>

                                <Collapse isOpened={userType === "persona"} className={"wc px-0 ro"}>
                                    <div className="row">
                                        <Field
                                            value={values.firstName}
                                            flex={"1 0 150px"}
                                            className={"p-2"}
                                            placeholder="Nombres"
                                            name="firstName"
                                            disabled={processing}
                                            onChange={(e) => setFieldValue('firstName', onlyLetters(e, true).substring(0, 40))}
                                            error={(errors.firstName && touched.firstName) && errors.firstName}
                                        />

                                        <Field
                                            value={values.lastName}
                                            flex={"1 0 150px"}
                                            className={"p-2"}
                                            placeholder="Apellidos"
                                            name="lastName"
                                            disabled={processing}
                                            onChange={(e) => setFieldValue('lastName', onlyLetters(e, true).substring(0, 40))}
                                            error={(errors.lastName && touched.lastName) && errors.lastName}
                                        />
                                        <Field
                                            customInput flex={"1 0 150px"}
                                            className={"p-2"}
                                            error={(errors.typeDoc && touched.typeDoc) && errors.typeDoc}
                                        >
                                            <select
                                                name="typeDoc"
                                                value={values.typeDoc}
                                                onChange={(e) => handleChangeTypeDocument(e, setFieldValue)}
                                                disabled={processing}
                                            >
                                                <option value="" disabled defaultValue>Tipo de Documento</option>
                                                {dynamicData.identityPerson && dynamicData.identityPerson.map((item) => (
                                                    <option value={item.codigo}>{item.nombre}</option>
                                                ))}
                                            </select>
                                        </Field>
                                        <Field
                                            flex={"1 0 150px"}
                                            className={"p-2"}
                                            placeholder="Número documento"
                                            name="docN"
                                            value={values.docN}
                                            disabled={processing || !values.typeDoc}
                                            onChange={(e) => {
                                                setFieldValue('docN', validateTypeDoc(values.typeDoc, e.target.value))
                                            }}
                                            type={values.typeDoc === "PPN" ? "" : "number"}
                                            error={(errors.docN && touched.docN) && errors.docN}
                                        />
                                        {expirationDateRequired && (
                                            <Field
                                                customInput flex={"1 0 150px"}
                                                className={"p-2"}
                                                error={(errors.documentExpirationDate && touched.documentExpirationDate) && errors.documentExpirationDate}
                                            >
                                                <input 
                                                    type={typeDate}
                                                    placeholder="Fecha de vencimiento"
                                                    name="documentExpirationDate"
                                                    defaultValue={values.documentExpirationDate}
                                                    onChange={(e) => setFieldValue('documentExpirationDate', e.target.value)}
                                                    min={`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`}
                                                    data-date-format="YYYY-MM-DD"
                                                    onFocus={() => setTypeDate('date')}
                                                    disabled={processing}
                                                />
                                            </Field>
                                        )}
                                    </div>
                                </Collapse>

                                <Field
                                    customInput flex={"1 0 100px"}
                                    className={"p-2"}
                                    error={(errors.country && touched.country) && errors.country}
                                >
                                    <select
                                        name="country"
                                        value={values.country}
                                        onChange={handleChange}
                                        disabled={processing}
                                    >
                                        <option value="" defaultValue>Colombia</option>
                                    </select>
                                </Field>

                                <Field
                                    flex={"1 0 200px"}
                                    className={"p-2"}
                                    placeholder="Número celular"
                                    name="phoneNumber"
                                    value={values.phoneNumber}
                                    disabled={processing}
                                    onChange={(e) => {
                                        setFieldValue('phoneNumber', e.target.value)
                                    }}
                                    type={"number"}
                                    format={"### ### ####"}
                                    before={() => <span className={"px-3"}>+57</span>}
                                    error={(errors.phoneNumber && touched.phoneNumber) && errors.phoneNumber}
                                />
                                <Field
                                    flex={"1 0 100%"}
                                    className={"p-2"}
                                    placeholder="Correo electrónico"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    disabled={processing}
                                    error={(errors.email && touched.email) && errors.email}
                                />

                                <Field
                                    flex={"1 0 100%"}
                                    className={"p-2"}
                                    placeholder="Confirme Correo electrónico"
                                    name="emailConfirmation"
                                    value={values.emailConfirmation}
                                    onChange={handleChange}
                                    disabled={processing || !values.email || errors.email}
                                    error={(errors.emailConfirmation && touched.emailConfirmation) && errors.emailConfirmation}
                                />
                            </div>

                            <TerminosCondidion
                                values={values}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                disabled={processing}
                                model={model}
                                termConditions={dynamicData.termConditions}
                            />

                            <RecaptchaComp
                                touched={touched}
                                setFieldValue={setFieldValue}
                                errors={errors}
                            />

                            <Btn
                                borderR={4}
                                type="submit"
                                loading={processing}
                                disabled={Object.values(errors)[0] && Object.values(touched)[0]}
                            >
                                Siguiente
                            </Btn>

                            <FormFooter/>
                        </form>
                    </div>
                )}
            </Formik>
        </Container>
    )
}

export default withRouter(React.memo(Fomulario));