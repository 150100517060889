
export const URL_BASE = process.env.REACT_APP_API_URL;
export const APY_KEY_RECAPTCHA = process.env.REACT_APP_CAPTCHA_KEY;

export let personValInit = {
    firstName: '',
    lastName: '',
    typeDoc: '',
    docN: '',
    documentExpirationDate: null,
    country: 'CO',
    phoneNumber: '',
    email: '',
    emailConfirmation: '',
    terms1: false,
    terms2: false,
    recapcha: false,
}

export let commerceValInit = {
    firstName: '',
    lastName: '',
    typeDoc: 'NIT',
    docN: '',
    digit: '',
    commerceName: '',
    country: 'CO',
    phoneNumber: '',
    email: '',
    emailConfirmation: '',
    terms1: false,
    terms2: false,
    recapcha: false,
}