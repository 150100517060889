import * as Yup from "yup";
import { dynamicData } from "./formulario";

export const PersonSchema = Yup.object().shape({
    firstName   : Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Debe ingresar al menos un nombre.'),
    lastName    : Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Debe ingresar al menos un apellido.'),
    typeDoc     : Yup.string()
        .required('Debe seleccionar un tipo de documento.'),
    docN   : Yup.string()
        .required('Debe ingresar un número de dpocumento.'),
    country     : Yup.string()
        .required('Required'),
    phoneNumber : Yup.string()
        .required('Debe ingresar un número de teléfono.'),
    email: Yup.string()
        .email('Correo no válido.')
        .required('Debe ingresar un correo electrónico.'),
    emailConfirmation: Yup.string()
        .required('Debe confirmar el correo.')
        .oneOf([Yup.ref('email'), null], 'El correo debe coincidir.'),
    terms1      : Yup.bool()
        .required('Debe aceptar los términos y condiciones.')
        .oneOf([true], "Debe aceptar los términos y condiciones."),
    terms2      : Yup.bool()
        .required('Debe aceptar las políticas de tratamientos de datos.')
        .oneOf([true], "Debe aceptar las políticas de tratamientos de datos."),
    recapcha      : Yup.bool()
        .required("Validación de de recaptcha no válida.")
        .oneOf([true], "Validación de de recaptcha no válida."),
    documentExpirationDate: Yup.date().when('typeDoc', (val, schema) => {
        if (val) {
            const auxIdenttyPerson = dynamicData.identityPerson?.find((item) => item.codigo === val);
            if (auxIdenttyPerson.expirationDateRequired) {
                return schema.nullable().required('Debe ingresar la fecha de vencimiento');
            }
        }
        return schema.nullable();
    })
});



export const CommerceSchema = Yup.object().shape({
    typeDoc     : Yup.string()
        .required('Debe seleccionar un tipo de documento.'),
    docN   : Yup.string()
        .required('Debe ingresar un número de dpocumento.'),
    country     : Yup.string()
        .required('Required'),
    phoneNumber : Yup.string()
        .required('Debe ingresar un número de teléfono.'),
    email: Yup.string()
        .email('Correo no válido.')
        .required('Debe ingresar un correo electrónico.'),
    emailConfirmation: Yup.string()
        .required('Debe confirmar el correo.')
        .oneOf([Yup.ref('email'), null], 'El correo debe coincidir.'),
    terms1      : Yup.bool()
        .required('Debe aceptar los términos y condiciones.')
        .oneOf([true], "Debe aceptar los términos y condiciones."),
    terms2      : Yup.bool()
        .required('Debe aceptar las políticas de tratamientos de datos.')
        .oneOf([true], "Debe aceptar las políticas de tratamientos de datos."),
    recapcha      : Yup.bool()
        .required("Validación de de recaptcha no válida.")
        .oneOf([true], "Validación de de recaptcha no válida."),
    digit       :Yup.string()
        .when("typeDoc", {
            is: 'nit',
            then: Yup.string().required("Debe ingresar el dígito único.")
        }),
    commerceName  :Yup.string()
        .min(3, "Debe ingresar al menos 3 caracteres.")
        .required("Debe ingresar el nombre de la empresa."),
});


