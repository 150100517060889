import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
    position        : relative;
    display         : flex;
    flex-flow       : ${props => props.direction} wrap;
    justify-content : ${props => props.jc};
    align-items     : ${props => props.alg};
    flex            : ${props => props.flex};
`;


const Flex = (props) => {
    return (
        <Container
            jc={props.jc}
            alg={props.alg}
            flex={props.flex}
            className={props.className}
            direction={props.direction}
            style={props.style}
        >
            {props.children}
        </Container>
    )
}

export default React.memo(Flex);
