import React from 'react';
import {Container} from "./styles";
import Flex from "../Flex/Flex";


const CheckCircle = (props) => {
    return (
        <Container
            active={props.active}
            disabled={props.disabled}
        >
            <input
                type="button"
                onClick={()=> props.set()}
                disabled={props.disabled}
                name={props.name}
            />
            <Flex direction={"column"} className={` chulo ${props.active && 'active'}`}>
                {props.active && <i className={"fa fa-check"}> </i>}
            </Flex>
        </Container>
    )
}

export default React.memo(CheckCircle);