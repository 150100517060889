import React from 'react';
import Field from "../UI/field/field";
import ShowError from "../UI/field/showError";

const NitComp = ({ values, touched, error, onChange, identity, processing }) => {
    return (
        <>
            <Field
                flex={"0 0 87px"}
                className={"px-2 pt-2"}
                error={!!((error.typeDoc   && touched.typeDoc) && error.typeDoc)}
                noError
            >
                <select
                    value={values.typeDoc}
                    onChange={(e)=> onChange('typeDoc', e.target.value)}
                    disabled={processing}
                >
                    {identity && identity.map((item) => (
                        <option value={item.codigo}>{item.nombre}</option>
                    ))}
                </select>
            </Field>

            <Field
                className={"px-2 pt-2"}
                flex={"1 0 50%"}
                placeholder={"Número de documento"}
                value={values.docN}
                onChange={e=>{
                    let val = e.target.value.substring(0,15)
                    onChange('docN', val)
                }}
                type={"number"}
                error={(error.docN && touched.docN)&& touched.docN}
                noError
                disabled={processing}

            />
            {values.typeDoc === "NIT" &&
                <Field
                    type={"number"}
                    className={"px-2 pt-2"}
                    flex={"1 0 87px"}
                    placeholder={"Dígito único"}
                    name={'digit'}
                    value={values.digit}
                    onChange={e=>{
                        let val =  e.target.value
                            .substring(0,1)
                            .replace(/[^0-9]/g, '')
                        onChange('digit', val)
                    }}
                    error={(error.digit && touched.digit) && touched.digit}
                    noError
                    disabled={processing}
                />
            }

            <ShowError error={(error.typeDoc   && touched.typeDoc) && error.typeDoc}/>
            <ShowError error={(error.docN && touched.docN) && error.docN}/>
            <ShowError error={(error.digit     && touched.digit) && error.digit} />
        </>
    )
}

export default React.memo(NitComp);