import React from "react";
import Flex from "../../UI/Flex/Flex";

const Tabs = ({userType, setUserType, model, disabled})=>{
    return(
        <Flex flex={"1 0 100%"} className={"tabs"}>
            {model === "agregador" && (
                <Flex flex={"1 0 48%"}>
                    <button disabled={disabled} className={userType === "persona" && 'active'} onClick={() => setUserType("persona")} flex={"1 0 100%"}>Soy
                        persona
                    </button>
                </Flex>
            )}
            <Flex flex={"1 0 48%"}>
                <button disabled={disabled} className={`${userType === "comercio" && 'active'} ${model === "gateway" && 'cursor-default'}`} onClick={() => setUserType("comercio")} flex={"1 0 100%"}>Soy
                    comercio
                </button>
            </Flex>
        </Flex>
    )
}
export default React.memo(Tabs);