import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {APY_KEY_RECAPTCHA} from "../../../../config";
import ShowError from "../../../UI/field/showError";
import Flex from "../../../UI/Flex/Flex";

const RecaptchaComp = ({
    errors,touched,setFieldValue
                       }) => {
    return (
        <Flex className={"wc pb-3 text-center "} jc={"center"}>
            <ReCAPTCHA
                sitekey={APY_KEY_RECAPTCHA}
                onChange={()=> setFieldValue('recapcha',true)}
            />
            <ShowError error={(errors.recapcha && touched.recapcha) && errors.recapcha}/>
        </Flex>
    )
}

export default React.memo(RecaptchaComp);