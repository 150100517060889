import React,{Component} from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from 'react-transition-group';
import Flex from "../UI/Flex/Flex";

export const Modal = props => {
    return(

        <CSSTransition
            in={props.show}
            timeout={300}
            classNames="fade"
            unmountOnExit
            onExited = {() => {
                return props.callback ? props.callback(): null
            }}
        >
            <Flex className={"modal-container f02"} direction={"column"} jc={"center"}>

                    <div className={`sombra-modal ${props.masOscuro && "masOscuro"}`}></div>
                    <div className="area wc f01 position-relative px-2">
                        {props.reset ?
                            props.children
                        :
                            <div className={`card  ${props.className}`}>
                                <div className="content p-3">
                                    {props.children}
                                </div>
                            </div>
                        }
                    </div>

            </Flex>
        </CSSTransition>

    )
};


class ShowModal extends Component{

    render() {
        // React does *not* create a new div. It renders the children into `domNode`.
        // `domNode` is any valid DOM node, regardless of its location in the DOM.
        return ReactDOM.createPortal(
            <Modal {...this.props} >{this.props.children}</Modal>,
            document.getElementById("cont-modal")
        );
    }

}

export default React.memo(ShowModal);



